export default defineNuxtRouteMiddleware(async to => {
  // console.log('categoryExists', { to, from })
  // console.log('categories.value', categories.value)
  const app = useNuxtApp()
  if (!useMenuStore().categories) {
    // console.log('categoriesPending.value', categoriesPending.value)
    await useMenuStore().fetchRootCategoriesOnce()
  }

  const categorySlug = to.params?.category
  if (categorySlug) {
    const category = useMenuStore().findCategoryBySlug(categorySlug)
    const storeType = useStoreType()
    const isCorrectDomain = computed(() => {
      if (storeType.isHunting && category?.isWeapon) {
        return true
      } else if (!storeType.isHunting && !category?.isWeapon) {
        return true
      } else if (useRequestURL().hostname.includes('localhost')) {
        return true
      }
      return false
    })
    // console.log('isCorrectDomain: ', isCorrectDomain.value)
    if (!category || category?.level !== 1 || !isCorrectDomain.value) {
      await app.runWithContext(() => useRedirect(to.fullPath, 'Category'))
      return false
    }

    const subCategorySlug = to.params?.subCategory

    if (subCategorySlug) {
      const subCategory = flatCategories(category.children).find(
        c => c.slug === subCategorySlug
      )

      if (!subCategory) {
        await app.runWithContext(() => useRedirect(to.fullPath, 'Subcategory'))
        // throw createError({
        //   statusCode: 404,
        //   statusMessage: `${messageType} Not Found`
        // })
        return false
      }
    }
  }

  return true
})
